<!--积分订单-->
<template>
    <div class="mainBox">
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb flex-wrap">
                <el-row>
                    <label>场景：</label>
                    <el-select v-model="select.college" placeholder="选择订单状态" clearable class="selectWidth" @change="curr=1;getList()">
                        <el-option v-for="item in selectData['订单状态']" :label="item.name" :value="item.name" :key="item.id"></el-option>
                    </el-select>
                    <el-date-picker v-model="select.book_time_end" class="selectTimeWidth" size="small" type="daterange" range-separator="至" start-placeholder="秒杀时间" end-placeholder="时间筛选" value-format="yyyy-MM-dd" @change="curr=1;getList()">
                    </el-date-picker>
                </el-row>
                <el-row>
                    <!-- <el-button type="success" block size="mini" @click="edit()">订单总金额：10元</el-button>
                    <el-button type="primary" block size="mini" @click="edit()">导出</el-button> -->
                </el-row>
                
            </div>
        </div>

        <!--主要内容-->
        <div class="table orderBox" style="height:calc( 100% - 175px );">
            <el-table :data="list" class="shopOrder" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column type="selection" align="center" width="55"> </el-table-column>
                <el-table-column label="订单信息" width="200" class="box">
                    <template slot-scope="scope">
                        <div class="order_des flex flex-ai-c">
                            <img class="imgSty" src="https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1607508642445&di=0e36873f5842d9a862bf9f8f2da19510&imgtype=0&src=http%3A%2F%2Fc.hiphotos.baidu.com%2Fzhidao%2Fpic%2Fitem%2Ff3d3572c11dfa9ecc052be8060d0f703918fc12d.jpg" alt="">
                            <div class="desFont">
                                <p class="pb_sty">
                                    <span>{{scope.row.name}}</span>
                                </p>
                                <p>积分价格：100</p>
                                <p>数量：80</p>
                                <p>小计：800</p>
                            </div>
                        </div>
                        <div class="item">
                            <span style="margin-left:1%">订单编号：678346587485 
                                <span>共<span style="color:#7B84FF">10</span>件</span>
                            </span>
                            <span class="hint_pr">创建时间：2020-4-23</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="address" align="center" label="实付"></el-table-column>
                <el-table-column prop="address" align="center" label="订单状态"></el-table-column>
                <el-table-column prop="address" label="买家信息">
                    <template slot-scope="scope">
                        <p>会员卡号：{{scope.row.name}}</p>
                        <p>联系人：{{scope.row.name}}</p>
                        <p>电话：{{scope.row.name}}</p>
                        <p>地址：{{scope.row.name}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="配货商家信息">
                    <template slot-scope="scope">
                        <p>门店名称：{{scope.row.name}}</p>
                        <p>门店地址：{{scope.row.name}}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="address"  label="配送人员信息">
                    <template slot-scope="scope">
                        <p>配送员：{{scope.row.name}}</p>
                        <p>联系方式：{{scope.row.name}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="200" class="shopOrders">
                    <template slot-scope="scope">
                        <el-button type="warning" plain size="mini" @click="setupPwd(scope.row)">退款</el-button>
                        <el-button type="danger" plain size="mini" @click="deletes(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                list:[{
                    id: 1,
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '12'
                }, {
                    id: 3,
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '12',
                }, {
                    id: 4,
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '12'
                }],
                selectData:[],
                select:{},
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        methods: {
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    };
</script>